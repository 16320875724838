import React from "react";
import {IAppliedFilter, IToolTemplateBlock} from "@/app/models/auctor";
import {evaluateExpressionWithFiltersAndRowData} from "@/app/utils/expression-utils";
import Image from "next/image";

interface IImageBlockProps {
    block: IToolTemplateBlock;
    expressionContext?: string;
    appliedFilters: IAppliedFilter[];
    toolData: any;
}

export default function ImageBlock({block, appliedFilters, toolData, expressionContext}: IImageBlockProps) {

    const getImageUrl = () => {
        if (block.image_url_expression) {
            const imageUrl = evaluateExpressionWithFiltersAndRowData(block.image_url_expression, toolData, appliedFilters, expressionContext)

            if (imageUrl) {
                return imageUrl
            }
        }

        return block.image_url
    }

    const imageSizeToClass = (size: string) => {
        switch (size) {
            case 'sm':
                return 'w-20 md:w-18 h-14'
            case 'md':
                return 'w-32 md:w-28 h-20'
            case 'lg':
                return 'w-48 md:w-40 h-32'
            case 'xl':
                return 'w-64 md:w-56 h-48'
            default:
                return 'w-32 md:w-28 h-20'
        }
    }

    return (
        <div className={`relative object-contain ${imageSizeToClass(block.image_size)}`}>
            <Image
                src={getImageUrl()}
                alt={block.image_alt ?? ''}
                fill
                className="object-contain"
                sizes="(max-width: 768px) 40vw, (max-width: 1200px) 30vw, 10vw"
            />
        </div>
    )
}
