import React from 'react';
import {IAppliedFilter} from "@/app/models/auctor";
import {evaluateExpressionWithFiltersAndRowData} from "@/app/utils/expression-utils";
import TableBlock from "@/app/components/auctor/tool/TableBlock";
import dynamic from "next/dynamic";
import PartnerLinkButton from "@/app/components/atomic/organisms/PartnerLinkButton";
import ImageBlock from "@/app/components/auctor/tool/ImageBlock";

const GaugeBlock = dynamic(() => import('@/app/components/auctor/tool/GaugeBlock'), {
    loading: () => <GaugeBlockSkeleton />
});

interface ISectionBlockProps {
    block: any;
    expressionContext?: string;
    appliedFilters: IAppliedFilter[];
    toolData: any;
}

export default function SectionBlock({block, expressionContext, appliedFilters, toolData}: ISectionBlockProps) {
    return (
        <div
            className={`col-span-${block.col_span} flex justify-center items-center`}
        >
            {block.type === 'text' && (
                <span
                    className={`text-${block.text_font_size} font-${block.text_font_weight}`}
                    style={{color: block.text_color}}
                >
                    {block.text_content}
                </span>
            )}

            {block.type === 'image' && (
                <ImageBlock
                    block={block}
                    appliedFilters={appliedFilters}
                    toolData={toolData}
                    expressionContext={expressionContext}
                />
            )}

            {block.type === 'expression' && (
                <div
                    className="w-full"
                    dangerouslySetInnerHTML={{
                        __html: evaluateExpressionWithFiltersAndRowData(block.expression, toolData, appliedFilters, expressionContext) ?? '',
                    }}
                />
            )}

            {block.type === 'button' && (
                <PartnerLinkButton
                    target={block.button_target_url}
                    size={block.button_size}
                    theme={block.button_theme}
                    label={block.button_label}
                />
            )}

            {block.type === 'gauge' && (
                <GaugeBlock
                    block={block}
                    appliedFilters={appliedFilters}
                    toolData={toolData}
                    expressionContext={expressionContext}
                />
            )}

            {block.type === 'table' && (
                <TableBlock
                    block={block}
                    appliedFilters={appliedFilters}
                    toolData={toolData}
                    expressionContext={expressionContext}
                />
            )}
        </div>
    );
}

function GaugeBlockSkeleton() {
    return (
        <div className={'flex flex-col justify-center items-center gap-2 relative'}>
            <div className={'w-[140px] h-[140px] bg-gray-400 rounded-full animate-pulse'} />
        </div>
    )
}


